import React, { Component } from "react";

export default class Project extends Component {
    showPopup = (message) => {
        const popup = document.createElement("div");
        popup.textContent = message;
        popup.style.position = "fixed";
        popup.style.top = "50%";
        popup.style.left = "50%";
        popup.style.transform = "translate(-50%, -50%)";
        popup.style.backgroundColor = "#333";
        popup.style.color = "#fff";
        popup.style.padding = "20px";
        popup.style.borderRadius = "10px";
        popup.style.boxShadow = "0 4px 6px rgba(0, 0, 0, 0.1)";
        popup.style.zIndex = "1000";

        document.body.appendChild(popup);

        setTimeout(() => {
            document.body.removeChild(popup);
        }, 2000);
    };

    handleProjectClick = (e, link) => {
        if (!link) {
            e.preventDefault(); // Prevent default anchor tag behavior
            this.showPopup("Preview not available");
        }
    };

    render() {
        let resumeData = this.props.resumeData;
        return (
            <section id="project">
                <div className="row">
                    <div className="twelve columns collapsed">
                        <h1>Check Out Some of My Projects!</h1>
                        <div
                            id="project-wrapper"
                            className="bgrid-quarters s-bgrid-thirds cf"
                            alt=""
                        >
                            {resumeData.projects &&
                                resumeData.projects.map((item, index) => (
                                    <a
                                        key={index}
                                        className="project-link"
                                        href={item.link || "#"}
                                        target={item.link ? "_blank" : "_self"}
                                        rel="noopener noreferrer"
                                        onClick={(e) =>
                                            this.handleProjectClick(e, item.link)
                                        }
                                    >
                                        <div className="columns project-item">
                                            <div
                                                className="item-wrap"
                                                style={{
                                                    cursor: item.link
                                                        ? "pointer"
                                                        : "default",
                                                }}
                                            >
                                                <img
                                                    src={`${item.imgurl}`}
                                                    className="item-img"
                                                    alt={item.name}
                                                />
                                                <div className="overlay">
                                                    <div className="project-item-meta">
                                                        <h5 className="project-name">
                                                            {item.name}
                                                        </h5>
                                                        <p className="project-description">
                                                            {item.description}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                ))}
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
