let resumeData = {
    "imagebaseurl": "https://www.cvinayreddy.com",
    "name": "Vinay",
    "role": "Software Developer with a Robotics background",
    "linkedinId": "https://www.linkedin.com/in/c-vinay-reddy/",
    "githubid": "https://github.com/the-go-getter",
    "socialLinks": [
        {
            "name": "linkedin",
            "url": "https://www.linkedin.com/in/c-vinay-reddy/",
            "className": "fa fa-linkedin fa-2x"
        },
        {
            "name": "github",
            "url": "https://github.com/the-go-getter",
            "className": "fa fa-github fa-2x"
        }
    ],
    "education": [
        {
            "UniversityName": "Northeastern University, Boston, MA, USA",
            "specialization": "M.S in Computer Science",
            "MonthOfJoining": "January",
            "YearOfJoining": "2019",
            "MonthOfPassing": "May",
            "YearOfPassing": "2022",
            "Achievements": "Courses: Foundations of A.I, Data mining techniques, Robotic Science and Systems, " +
                "Web Development, Database Management Systems, Object Oriented Design, Computer Systems"
        },
        {
            "UniversityName": "Manipal University, Manipal, India",
            "specialization": "B.Tech in Mechatronics engineering",
            "MonthOfJoining": "August",
            "YearOfJoining": "2014",
            "MonthOfPassing": "May",
            "YearOfPassing": "2018",
            "Achievements": "Courses: Robot Path Planning, Robot dynamics and control, Intelligent Controllers, " +
                " Mechanics of Robotic Systems, Machine Vision and Image Processing"
        }
    ],
    "work": [
        {
            "Title": "Software Engineer, Founding team",
            "CompanyName": "CStoreIQ",
            "Location": "Huntsville, AL, USA",
            "MonthOfJoining": "Jun",
            "YearOfJoining": "2023",
            "MonthOfLeaving": "Present",
            "YearOfLeaving": "",
            "Description": [
                { "subheading": "Cross-functional Responsibilities", "details": "Carrying out cross-functional responsibilities as one of the first employees in the North America region." },
                { "subheading": "API Integration", "details": "Integrated third-party APIs for supporting vendor loyalty programs on point-of-sale systems using AWS Lambda, Python, SQL, and Angular, projected to grow the customer base by ~25%." },
                { "subheading": "REST API Development", "details": "Designed, developed, and deployed REST APIs powered by Python FastAPI and AWS EKS, S3 for supporting traffic from 4000+ point-of-sale systems across the United States." },
                { "subheading": "Fintech Application", "details": "Led the end-to-end development of a fintech application built with Angular, Python, AWS API Gateway, Lambda, S3, and PostgreSQL, successfully shipping the beta version to support 3000+ distributors and outlets." }
            ]
        },
        {
            "Title": "Software Engineer (SWE3)",
            "CompanyName": "Mercari",
            "Location": "Palo Alto, CA, USA",
            "MonthOfJoining": "May",
            "YearOfJoining": "2022",
            "MonthOfLeaving": "Apr",
            "YearOfLeaving": "2023",
            "Description": [
                { "subheading": "E-commerce Marketing Campaign", "details": "Collaborated with multiple stakeholders to gather requirements, build, test, and successfully ship an endpoint to support an e-commerce marketing campaign, increasing active listings by 15% MoM." },
                { "subheading": "Cost Optimization", "details": "Saved $14K yearly by optimizing customer data points sent from the Golang-based CRM integration microservice to Braze by ~5%." },
                { "subheading": "System Reliability", "details": "Resolved notification subscription issues impacting 23,000 users in a production application running Golang and PHP microservices on GKE." },
                { "subheading": "Service Migration", "details": "Implemented email service migration from SendGrid to Braze, saving the company $120K annually." },
                { "subheading": "Security Enhancements", "details": "Devised and executed a strategy for rotating sensitive credentials for a live application, mitigating security risks for backend CRM systems." }
            ]
        },
        {
            "Title": "Software Engineer Co-op",
            "CompanyName": "Motorola Solutions",
            "Location": "Somerville, MA, USA",
            "MonthOfJoining": "May",
            "YearOfJoining": "2021",
            "MonthOfLeaving": "Jan",
            "YearOfLeaving": "2022",
            "Description": [
                { "subheading": "Test Log Visualization", "details": "Visualized test logs of multiple firmware releases for surveillance cameras using MongoDB, Python, Flask, and React.js, saving hundreds of hours and improving administrative communication." },
                { "subheading": "Code Issue Resolution", "details": "Collaborated with the surveillance camera firmware and video analytics teams to resolve 48 Python production code issues within 12 sprints." },
                { "subheading": "QA Automation", "details": "Automated preliminary QA processes for video analytics software on various camera models, developing a test suite with Python's pytest library to detect functional issues early." }
            ]
        },
        {
            "Title": "Scientific Intern",
            "CompanyName": "Merck/EMD Serono",
            "Location": "Burlington, MA, USA",
            "MonthOfJoining": "Aug",
            "YearOfJoining": "2020",
            "MonthOfLeaving": "Nov",
            "YearOfLeaving": "2020",
            "Description": [
                { "subheading": "SharePoint Migration", "details": "Led SharePoint webpage migration for the biopharma group, ensuring a seamless transition for 200+ users within a month." },
                { "subheading": "Database Optimization", "details": "Streamlined Control Terminology databases using SQL scripts, resolving redundancies and enhancing data consistency by 30%." },
                { "subheading": "Web Scraping", "details": "Employed Python libraries for web scraping, optimizing parsing of HTML, XML, and JSON data." },
                { "subheading": "Bug Resolution", "details": "Authored technical documentation and identified/resolved three critical bugs in the Standard Exemption Request application." }
            ]
        },
        {
            "Title": "Junior Software Developer",
            "CompanyName": "Anram Solutions Pvt. Ltd.",
            "Location": "Hyderabad, India",
            "MonthOfJoining": "Jul",
            "YearOfJoining": "2018",
            "MonthOfLeaving": "Dec",
            "YearOfLeaving": "2018",
            "Description": [
                { "subheading": "Website Development", "details": "Built websites using Angular, Java, JPA, Hibernate, Spring Boot, PostgreSQL, and AWS to improve business visibility." }
            ]
        },
        {
            "Title": "Intern",
            "CompanyName": "Polytech Automation Pvt. Ltd.",
            "Location": "Bangalore, India",
            "MonthOfJoining": "May",
            "YearOfJoining": "2017",
            "MonthOfLeaving": "Jun",
            "YearOfLeaving": "2017",
            "Description": [
                { "subheading": "Foundry Work", "details": "Worked in the foundry unit manufacturing components for transit mixers and concrete batching plants." },
                { "subheading": "Manufacturing Automation", "details": "Gained experience in large-scale manufacturing techniques and construction automation by operating these machines." }
            ]
        },
        {
            "Title": "Summer Intern",
            "CompanyName": "Sands Precision Pvt. Ltd.",
            "Location": "Bangalore, India",
            "MonthOfJoining": "Jun",
            "YearOfJoining": "2016",
            "MonthOfLeaving": "Jul",
            "YearOfLeaving": "2016",
            "Description": [
                { "subheading": "CNC Engineering", "details": "Engineered automated CNC machinery, showcasing skills in machine shop operations, assembly coding, and fabrication processes." },
                { "subheading": "Production Efficiency", "details": "Accelerated production rate by 25% under tight deadlines and learned Kannada for better team communication." }
            ]
        }
    ],
    "skillsDescription": "",
    "skillset": [
        {
            "skillname": "GoLang",
            "icon":"../images/icons/Go.svg"
        },
        {
            "skillname": "Python",
            "icon":"../images/icons/python.png"
        },
        {
            "skillname": "SQL",
            "icon":"../images/icons/SQL.png"
        },
        {
            "skillname": "Java",
            "icon":"../images/icons/java.png"
        },
        {
            "skillname": "JavaScript",
            "icon":"../images/icons/JavaScript.png"
        },
        {
            "skillname": "C++",
            "icon":"../images/icons/C++.png"
        },
        {
            "skillname": "Google cloud platform (GCP)",
            "icon":"../images/icons/GCP.png"
        },
        {
            "skillname": "Amazon Web Services (AWS)",
            "icon":"../images/icons/AWS.png"
        },
        {
            "skillname": "PHP",
            "icon":"../images/icons/php.png"
        },
        {
            "skillname": "Docker",
            "icon":"../images/icons/docker.png"
        },
        {
            "skillname": "Kubernetes",
            "icon":"../images/icons/kubernetes.png"
        }
    ],
    "projects": [
        {
            "description": "Data-driven analysis on chances of a heart disease",
            "name": "Cardiovascular disease predictor",
            "imgurl" : "https://partheniumprojects.com/wp-content/uploads/2018/12/Heart-Disease-Prediction-1.png",
            "link": "https://github.com/the-go-getter/Heart-disease-prediction"
        },
        {
            "description": "Python web crawler, page ranker and visualizer",
            "name": "Search Engine",
            "imgurl" : "https://totheweb-com.exactdn.com/wp-content/uploads/2018/09/Search-Engine-Simulator-80_20fbd47708ae8fa220591a57cb64df2c.jpg?strip=all&lossy=1&quality=85&ssl=1",
            "link": "https://github.com/the-go-getter/Page-ranker"
        },
        {
            "description": "Website providing stock market investment suggestions",
            "name": "Stocks4all",
            "imgurl" : "https://www.uaebarq.ae/en/wp-content/uploads/2018/05/stock-market.jpg",
            "link": "https://github.com/the-go-getter/Stocks-for-all-client"
        },
        {
            "description": "Java based chat application",
            "name": "Prattle chat",
            "imgurl" : "https://colorlib.com/wp/wp-content/uploads/sites/2/wordpress-live-chat-plugins.png",
            "link": "https://github.com/the-go-getter/Java-chat-app"
        },
        {
            "description": "Insights into their living conditions",
            "name": "Database for the homeless",
            "imgurl" : "https://media.istockphoto.com/photos/helping-hand-picture-id952953508?k=6&m=952953508&s=170667a&w=0&h=Z3uM8MklFSneTzzL6GYpSRFOiqR0ohF-5SF87zwFndg=",
            "link": "https://vinay1020287.wixsite.com/5200proj"
        },
        {
            "description": "Application for creating SVG animations",
            "name": "Easy Animator",
            "imgurl" : "https://www.bloopanimation.com/wp-content/uploads/2015/11/you-wont-be-an-animator-1103x575.jpg",
            "link": "https://github.com/the-go-getter/Java-animator"
        },
        {
            "description": "Python game inspired by SEGA's Space invaders",
            "name": "Pokémon vs. Pollutionators",
            "imgurl" : "https://www.thequiz.com/wordpress/wp-content/uploads/2018/07/https_2F2Fblueprint-api-production.jpg",
            "link": "https://github.com/the-go-getter/Pokemon-vs-Pollutionators"
        },
        {
            "description": "Floor cleaning robot using Raspberry Pi",
            "name": "Automated Smart Home Cleaner",
            "imgurl" : "https://circuitdigest.com/sites/default/files/inlineimages/automatic-home-cleaning-robot-using-arduino-and-ultrasonic.jpg",
            "link": ""
        },
        {
            "description": "Digital Signal Processing techniques applied through MATLAB",
            "name": "Image Processing Application",
            "imgurl" : "https://circuitdigest.com/sites/default/files/inlineimages/u/Image-Processing-Output-using-MATLAB-GUI.jpg",
            "link": ""
        },
        {
            "description": "Few sensors + Microcontrollers = Lots of possibilities!",
            "name": "Automated Car Wash Plant",
            "imgurl" : "https://d2t1xqejof9utc.cloudfront.net/screenshots/pics/881b6f08f7095ce7ffdde34e6db007c7/large.jpg",
            "link": ""
        },
    ]
};

export default resumeData;
