import React, { Component } from 'react';

export default class Resume extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expandedItems: {},
        };
    }

    toggleExpand = (index) => {
        this.setState((prevState) => ({
            expandedItems: {
                ...prevState.expandedItems,
                [index]: !prevState.expandedItems[index],
            },
        }));
    };

    render() {
        let resumeData = this.props.resumeData;
        return (
            <section id="resume">
                <div className="row education">
                    <div className="three columns header-col">
                        <h1><span>Education</span></h1>
                    </div>
                    <div className="nine columns main-col">
                        {resumeData.education && resumeData.education.map((item, index) => (
                            <div key={index} className="row item education-item">
                                <div className="twelve columns">
                                    <h4>{item.UniversityName}</h4>
                                    <p className="info">
                                        {item.specialization}
                                        <span>&bull;</span>
                                        <em className="date">
                                            {item.MonthOfJoining} {item.YearOfJoining} - {item.MonthOfPassing} {item.YearOfPassing}
                                        </em>
                                    </p>
                                    <p className="description">{item.Achievements}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="row work">
                    <div className="one column header-col work-header">
                        <h1><span>Work</span></h1>
                    </div>
                    <div className="twelve columns main-col">
                        {resumeData.work && resumeData.work.map((item, index) => (
                            <div key={index} className="row item">
                                <div className="timeline">
                                    <div className="entry">
                                        <div className="title">
                                            <h3>
                                                <em className="date">
                                                    {item.MonthOfJoining} {item.YearOfJoining} - {item.MonthOfLeaving} {item.YearOfLeaving}
                                                </em>
                                            </h3>
                                            <p className="info">{item.Location}</p>
                                        </div>
                                        <div className="body">
                                            <h6
                                                className="role"
                                                onClick={() => this.toggleExpand(index)}
                                                style={{
                                                    cursor: 'pointer',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <span style={{ marginRight: '10px' }}>
                                                    {this.state.expandedItems[index] ? "▼" : "►"}
                                                </span>
                                                {item.Title}, {item.CompanyName}
                                            </h6>
                                            <div
                                                className="description-container"
                                                style={{
                                                    maxHeight: this.state.expandedItems[index] ? "500px" : "0",
                                                    opacity: this.state.expandedItems[index] ? "1" : "0",
                                                    transition: "max-height 0.3s ease-in-out, opacity 0.3s ease-in-out",
                                                    overflow: "hidden",
                                                }}
                                            >
                                                <ul className="description">
                                                    {item.Description.map((desc, i) => (
                                                        <li key={i}>
                                                            <strong>{desc.subheading}:</strong> {desc.details}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="row skill">
                    <div className="two columns header-col">
                        <h1><span>Skills</span></h1>
                    </div>
                    <div className="ten columns main-col">
                        {resumeData.skillset && resumeData.skillset.map((skill, index) => (
                            <div key={index} className="three columns skill-item">
                                <img
                                    src={skill.icon}
                                    alt={skill.skillname}
                                    style={{ maxHeight: '70px', marginRight: '10px' }}
                                />
                                <h5>{skill.skillname}</h5>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        );
    }
}
