import React, { Component } from 'react';

export default class About extends Component {
    render() {
        let resumeData = this.props.resumeData;
        return (
            <section id="about">
                <div className="row">
                    <div className="three columns">
                        <img className="profile-pic" src="../../public/images/profilepic.jpg" alt="" />
                    </div>
                    <div className="bg"></div>
                    <div className="bg bg2"></div>
                    <div className="bg bg3"></div>
                    <div className="content nine columns main-col">
                        <h2>About Me</h2>
                        <p>
                        <strong> 6+ years in industry:</strong><br />
                            <span>&#8226;</span> 4 years in Software Engineering, with contributions to retail, e-commerce, surveillance, and pharmaceutical sectors<br />
                            <span>&#8226;</span> 1 year each in Educational services and Marketing, and 4 months in Industrial Automation<br /><br />
                        <strong> Global collaboarations:</strong><br />
                            <span>&#8226;</span> Worked across 3 continents <br />
                            <span>&#8226;</span> Emphasized teamwork, innovation and impactful solutions in diverse cultural settings<br /><br />
                        <strong> Unique skillset:</strong><br />
                            <span>&#8226;</span> Computer Science graduate with focus on Software Development & A.I, <br />
                            <span>&#8226;</span> Mechatronics undergrad with specialization in Robotics<br /><br />
                        <strong>Full-stack engineer:</strong><br />
                            <span>&#8226;</span> Proficient in Python, Go, PHP, JavaScript, Java, C++, SQL, Docker, Kubernetes, <br />
                            Large scale cloud apps, AWS, GCP, Grafana, Prometheus, Kibana, Jenkins, JIRA, <br />
                            Agile process, Documentation, Testing, Generative AI, LLMs, and Prompt engineering<br /><br />
                        </p>
                    </div>
                </div>
            </section>
        );
    }
}
